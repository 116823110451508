import { useCallback, useEffect, useState } from "react";

import { rtbInstance } from "@api/instance";
import { useQuery } from "@tanstack/react-query";

import { handleApiError } from "@features/config";

import { Skill } from "@dto/skill";

type Request = {
  searchContent?: string;
};
type Response = {
  success: boolean;
  data?: { data: Skill[]; totalItems?: number };
  message?: string;
};

export const findUserSkills = async ({ searchContent }: Request): Promise<Response> => {
  try {
    const params = { params: { name: searchContent } };
    const { data } = await rtbInstance.get(`/user-skills`, params);
    return { success: true, data };
  } catch (error) {
    return handleApiError(error);
  }
};

const useFindUserSkills = (initParams: Partial<Request>) => {
  const [params, setParams] = useState(initParams);
  const { data, refetch, ...rest } = useQuery({
    queryKey: ["findUserSkills", params],
    queryFn: () => findUserSkills(params),
  });

  const handleChangeParams = useCallback((params: Partial<Request>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  return {
    skills: data?.data?.data || [],
    totalItems: data?.data?.totalItems || 0,
    handleChangeParams,
    ...rest,
  };
};

export default useFindUserSkills;
