export enum RoundtableTypeEnum {
  // round table
  TEAM = "TEAM",
  // project
  SELF = "SELF",
  FREELANCER = "FREELANCER",
  // Deprecated
  EXTERNAL_SELF = "EXTERNAL_SELF",
  EXTERNAL_FREELANCER = "EXTERNAL_FREELANCER",
}
type RoundtableTypeKeys = keyof typeof RoundtableTypeEnum;
export type RoundtableType = (typeof RoundtableTypeEnum)[RoundtableTypeKeys];
