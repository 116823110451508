import { useCallback, useEffect, useState } from "react";

import { rtbInstance } from "@api/instance";
import { useQuery } from "@tanstack/react-query";

import { Roundtable } from "@dto/roundtable";

import { RoundtableTypeEnum, RoundtableType } from "@@types/type";

type Response = {
  data: Roundtable[];
  totalItems: number;
};

export interface JoinedProjectsRequest {
  types: RoundtableType[];
  userId: string;
  page: number;
  pageSize: number;
  title?: string;
  isClosed?: boolean;
}

const getOwnTraditionalRoundtable = async ({
  page = 0,
  pageSize = 5,
  types,
  userId,
}: Partial<JoinedProjectsRequest>): Promise<Response> => {
  const response = await rtbInstance.get(`/round-tables/owner-traditional`, {
    params: {
      pageSize,
      type: types
        ? types.join(",")
        : [RoundtableTypeEnum.FREELANCER, RoundtableTypeEnum.SELF].join(","),
      page: page + 1,
      userId,
    },
  });

  return response.data;
};

export const getJoinedProjects = async ({
  title,
  page = 0,
  pageSize = 5,
  types,
  userId,
  isClosed,
}: Partial<JoinedProjectsRequest>): Promise<Response> => {
  const response = await rtbInstance.get(`/round-tables/joined`, {
    params: {
      title,
      pageSize,
      type: types
        ? types.join(",")
        : [RoundtableTypeEnum.FREELANCER, RoundtableTypeEnum.SELF].join(","),
      page: page + 1,
      userId,
      isClosed,
    },
  });

  return response.data;
};

const useGetOwnTraditonalRoundtable = (initParams: Partial<JoinedProjectsRequest>) => {
  const [params, setParams] = useState(initParams);

  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useQuery({
    queryKey: ["getOwnTraditionalRoundtable", params],
    queryFn: () => getOwnTraditionalRoundtable({ ...params }),
  });

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  const handleChangeParams = useCallback((params: Partial<JoinedProjectsRequest>) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  return {
    data: data?.data || [],
    totalItems: data?.totalItems || 0,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    params,
    handleChangeParams,
  };
};

export default useGetOwnTraditonalRoundtable;
