import axios, { AxiosResponse } from "axios";

// import { _login } from "@utils/auth";

function onResponse(response: AxiosResponse) {
  response.data = response.data?.data;
  return response;
}

function onError(error: any) {
  // const { response } = error;
  // if (response?.status === 401) {
  //   _login("_blank");
  // }

  return Promise.reject(error);
}

export const rtbInstance = axios.create({
  baseURL: process.env.REACT_APP_RTB_ENDPOINT,
  withCredentials: true,
});

rtbInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

rtbInstance.interceptors.response.use(onResponse, onError);
