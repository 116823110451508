import { TypographyStyle } from "@mui/material";

import { Priority } from "./types";

export const formatPriorityStyle = (type: string): TypographyStyle => {
  const styles: TypographyStyle = {
    borderRadius: 4,
    fontSize: 12,
    textAlign: "center",
    p: 0.5,
    px: 1,
  };
  switch (type) {
    case Priority.URGENT:
      return {
        ...styles,
        color: "common.partyTime",
        backgroundColor: "common.wePeep",
      };
    case Priority.HIGH:
      return {
        ...styles,
        color: "common.mediumText",
        backgroundColor: "common.mediumPriorty",
      };
    case Priority.MEDIUM:
      return {
        ...styles,
        color: "common.heirloomHydrangea",
        backgroundColor: "#BEE3F8",
      };
    case Priority.LOW:
      return {
        ...styles,
        color: "#1B5E20",
        backgroundColor: "#E8F5E9",
      };
    default:
      return {
        ...styles,
        // color: "common.gray5",
        // backgroundColor: "common.wolfram",
      };
  }
};
