import { ENV } from "@config/env";

import { IziCrypto } from "./crypto";

export function _login(target?: string) {
  const key = IziCrypto.generateKey();
  localStorage.setItem("auth_key", key);
  localStorage.setItem("recent_path", location.pathname + location.search);
  const url = `${ENV.RTB_APP_URL}/verify-account?${new URLSearchParams({
    r: "gtl",
    k: key,
  }).toString()}`;
  if (target === "_blank") {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
}
