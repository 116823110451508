import { useEffect, useMemo } from "react";

import { getMe } from "@api/rtb/private/user";
import { Avatar, Box, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { _login } from "@utils/auth";
import { IziCrypto } from "@utils/crypto";

import { useUserStore } from "@contexts/user.state";

import { ENV } from "@config/env";

import Button from "./Button";

export const LoginBtn = () => {
  const { user, setUser } = useUserStore();
  const getMeQuery = useQuery({
    queryFn: getMe,
    queryKey: ["getMe"],
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getMeQuery.isFetched) {
      return;
    }
    if (getMeQuery.data?.data) {
      localStorage.setItem("user", JSON.stringify(getMeQuery.data.data));
      localStorage.removeItem("recent_path");
      setUser(getMeQuery.data.data);
    }
    if (getMeQuery.isError) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      setUser(null);
    }
  }, [getMeQuery.isFetched]);

  const onClick = () => {
    if (!user) {
      return _login();
    }
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    // location.reload();
    const key = IziCrypto.generateKey();
    localStorage.setItem("auth_key", key);
    localStorage.setItem("recent_path", location.pathname);
    window.location.href = `${ENV.RTB_APP_URL}/verify-account?${new URLSearchParams({
      r: "gtl",
      a: "out",
      k: key,
    }).toString()}`;
  };
  const btnLabel = useMemo(() => {
    if (getMeQuery.isFetched) {
      return user?.username ? "Logout" : "Login";
    }
    return null;
  }, [getMeQuery.isFetched, user]);
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={2}>
      {user && <Avatar src={user?.avatar} />}
      <Button
        variant="contained"
        sx={{
          borderRadius: "10px",
          textTransform: "unset",
        }}
        onClick={onClick}
      >
        {getMeQuery.isFetching && <CircularProgress color="inherit" size={24} />}
        {btnLabel}
      </Button>
    </Box>
  );
};
