import { RouterProvider } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { iziRoute } from "@routers/routes";
import theme from "@theme/index";

import SnackbarProvider from "@contexts/SnackbarContext";
import ViewportProvider from "@contexts/ViewportContext";

export const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <ViewportProvider>
        <SnackbarProvider>
          <RouterProvider router={iziRoute.getRouter()} />
        </SnackbarProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
};
