import { ReactNode } from "react";

import { SxProps } from "@mui/material";
import { Dayjs } from "dayjs";

export type FieldSchemas = {
  [x: string]: InputComponentProps & {
    gridCol?: any;
    key: string;
  };
};

export type fieldNameSchemas =
  | "title"
  | "description"
  | "files"
  | "deliverables"
  | "skills"
  | "budget"
  | "project"
  | "regional";
export type InputComponentProps = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  step?: number;
  component?: (typeof INPUT_COMPONENT_TYPE)[keyof typeof INPUT_COMPONENT_TYPE];
  componentProps?: any;
  options?: { value: string; text: string }[];
  name?: fieldNameSchemas;
  sx?: SxProps;
  icon?: ReactNode;
  overview?: ReactNode;
  helperText?: ReactNode;
};

export const INPUT_COMPONENT_TYPE = {
  AVATAR_UPLOAD: "avatarUpload",
  INPUT_TEXT: "inputText",
  INPUT_NUMBER: "inputNumber",
  AUTOCOMPLETE: "autoComplete",
  RADIO: "radio",
  AREA: "area",
  DATE_PICKER: "datePicker",
  PROJECT: "project",
  FILE_UPLOAD: "fileUpload",
  CHECKBOX: "checkbox",
} as const;
export const QuestVisibility = {
  PLATFORM: "platform",
  PROJECT: "project",
} as const;

type QuestVisibilityType = (typeof QuestVisibility)[keyof typeof QuestVisibility];

export const Priority = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  URGENT: "Urgent",
};

export type PostJobFormType = {
  title: string;
  description: string;
  files: File[];
  deliverables: string;
  skills: { id: string; name: string }[];
  priority?: string;
  detailOfWork?: string;
  visibility?: QuestVisibilityType;
  budget: number;
  dueDate: string;
  project: {
    rtbId?: string;
    id: string;
    name: string;
  };
  regional: string;
};
