import { useState, useMemo } from "react";
// import { GiPlainCircle } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Logout as LogoutIcon,
  FacebookOutlined as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import {
  Stack,
  Box,
  Grid,
  IconButton,
  useTheme,
  SvgIcon,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { ProtectedPaths, PublicPaths } from "@routers/path/PublicPath";
import { iziRoute } from "@routers/routes";

import Button from "@components/Button";
import { LoginBtn } from "@components/LoginBtn";

import { IziCrypto } from "@utils/crypto";

import { ReactComponent as BriefcaseIcon } from "@assets/images/gatherlance-landing/briefcase.svg";
import { ReactComponent as MediumIcon } from "@assets/images/gatherlance-landing/medium.svg";
import { ReactComponent as UnitedKingdomIcon } from "@assets/images/gatherlance-landing/united-kingdom.svg";

import { useViewport } from "@contexts/ViewportContext";
import { useUserStore } from "@contexts/user.state";

import { ENV } from "@config/env";

import Typography from "./GatherlanceTypography";

const aboutLinks = [
  { label: "About us", href: "#" },
  { label: "Press center", href: "#" },
  { label: "How Roundtable Works", href: "#" },
];

const supportLinks = [
  { label: "Help Center", href: "#" },
  { label: "Term & Policy", href: "#" },
];

export const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const {
    palette: { common },
  } = useTheme();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;
  const { user } = useUserStore();

  const onClickPostJob = () => {
    if (user) {
      iziRoute.navigate(iziRoute.getPathPattern(ProtectedPaths.postJob));
    } else {
      const key = IziCrypto.generateKey();
      localStorage.setItem("auth_key", key);
      localStorage.setItem("recent_path", ProtectedPaths.postJob);
      window.location.href = `${ENV.RTB_APP_URL}/verify-account?${new URLSearchParams({
        r: "gtl",
        k: key,
      }).toString()}`;
    }
  };
  const renderMenu = () => {
    return (
      <Stack
        direction={isMobileDesign ? "column" : "row"}
        gap={3}
        justifyItems={"center"}
        alignItems={"center"}
      >
        {isMobileDesign ? (
          <FormControl sx={{ m: 1, width: "100%", color: "white" }} variant="outlined">
            <OutlinedInput
              placeholder="What are you looking for?"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "black" }} />
                </InputAdornment>
              }
              sx={{ bgcolor: "white" }}
            />
          </FormControl>
        ) : (
          <IconButton onClick={() => iziRoute.navigate("/search")}>
            <SearchIcon />
          </IconButton>
        )}

        {user && (
          <Button
            type="button"
            variant="text"
            onClick={() => (window.location.href = `${ENV.RTB_APP_URL}/dashboard`)}
            sx={{
              borderRadius: "10px",
              textTransform: "unset",
            }}
          >
            My dashboard
          </Button>
        )}
        <Button
          type="button"
          variant="contained"
          onClick={onClickPostJob}
          sx={{
            borderRadius: "10px",
            textTransform: "unset",
          }}
        >
          Post a job
        </Button>
        {isDesktop && (
          <Stack
            onClick={() =>
              iziRoute.navigate(iziRoute.getPathPattern(PublicPaths.freelance))
            }
            flexDirection={"row"}
            gap={1}
            borderRadius={"24px"}
            p={"10px 24px"}
            border={"1px solid"}
            borderColor={"common.border"}
            sx={{
              cursor: "pointer",
            }}
          >
            <BriefcaseIcon />
            <Typography sx={{ userSelect: "none" }} gap={2}>
              I’m a freelancer
            </Typography>
          </Stack>
        )}
        <LoginBtn />
        {/* {isDesktop && <Avatar />} */}
      </Stack>
    );
  };

  return (
    <Stack px={{ sm: 3, md: 10 }}>
      <Stack
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        height={"70px"}
        px={isMobile ? 2 : 0}
        borderBottom={isMobileDesign ? "none" : common.border}
      >
        <Stack
          component={"a"}
          href="/landing"
          // onClick={() => iziRoute.navigate(iziRoute.getPathPattern(PublicPaths.landing))}
        >
          <img
            src={"/gatherlance_brand_dark.png"}
            alt="heading-logo"
            height={isMobileDesign ? 32 : 48}
          />
        </Stack>

        {isMobileDesign ? (
          <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} sx={{ px: 0 }}>
            {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        ) : (
          <Stack flexDirection={"row"} gap={2} alignItems="center">
            {renderMenu()}
          </Stack>
        )}
      </Stack>

      {isMobileDesign && openMobileMenu && (
        <Stack rowGap={2} p={3}>
          {renderMenu()}
        </Stack>
      )}
    </Stack>
  );
};

export const Footer = () => {
  const { isMobile, isTablet, isDesktop } = useViewport();
  const isMobileDesign = isMobile || isTablet;

  return (
    <>
      <Box
        display={isMobile ? "block" : "flex"}
        justifyContent="space-between"
        py={{ xs: 5, md: 8 }}
        px={{ xs: 3, md: 10 }}
        bgcolor={"black"}
      >
        <Stack gap={4}>
          <Box
            component="img"
            src={"/gatherlance_brand_light.png"}
            width={isMobileDesign ? 118 : 147}
            mb={isMobile ? 3 : 0}
          />
          <Typography
            width={200}
            color={"common.gray3"}
            fontSize={"16px"}
            lineHeight={"24px"}
          >
            We upgrade freelance to 2.0
          </Typography>
        </Stack>

        <Grid
          container
          columnSpacing={isDesktop ? 10 : 2}
          rowGap={3}
          justifyContent={isMobile ? "start" : "flex-end"}
        >
          <Grid item xs={11} tablet={6} sm={3}>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                ABOUT
              </Typography>
              <Stack rowGap={1.5}>
                {aboutLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack rowGap={2.5}>
              <Typography fontWeight={700} color={"white"}>
                SUPPORT
              </Typography>
              <Stack rowGap={1.5}>
                {supportLinks.map((link, index) => {
                  return (
                    <Typography
                      key={`${index}-${link.label}-about-section`}
                      color={"common.gray3"}
                    >
                      {link.label}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack py={{ xs: 5, md: 8 }} px={{ xs: 3, md: 10 }} bgcolor={"black"} gap={3}>
        <Stack
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          gap={3}
        >
          <Button
            startIcon={
              <SvgIcon>
                <UnitedKingdomIcon />
              </SvgIcon>
            }
            sx={{
              color: "white",
              fontWeight: 400,
              width: "fit-content",
              border: "1px solid white",
              borderRadius: 2,
            }}
          >
            English
          </Button>
          <Stack flexDirection={"row"} alignItems={"center"} gap={2} color={"white"}>
            <LinkedInIcon />
            <FacebookIcon />
            <MediumIcon />
            <XIcon />
          </Stack>
        </Stack>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          columnGap={6}
          rowGap={1}
        >
          <Typography color={"white"}>© {new Date().getFullYear()} Roundtable</Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            columnGap={3}
            rowGap={1}
          >
            <Typography color={"common.bellflowerBlue"}>Terms of Use</Typography>
            <Typography color={"common.bellflowerBlue"}>Cookie Policy</Typography>
            <Typography color={"common.bellflowerBlue"}>Privacy Notice</Typography>
          </Box>
        </Box>
      </Stack>
    </>
  );
};
