import { FC } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  title?: string;
  description?: string;
  submitBtnLabel?: string;
  cancelBtnLabel?: string;
}

const ConfirmDialog: FC<IProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
  submitBtnLabel,
  cancelBtnLabel
}) => {
  
  return (
    <Dialog
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: 540,
          m: 2,
        },
      }}
    >
      <DialogContent sx={{ pb: 6 }}>
        <Stack spacing={3} direction="row" mt={1}>
          <Stack rowGap={1}>
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>{title}</Typography>
            <Typography sx={{ fontSize: 16, color: "#718096" }}>{description}</Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          {cancelBtnLabel || "Cancel"}
        </Button>
        {onSubmit && (
          <Button
            variant="contained"
            onClick={() => {
              onSubmit();
              onClose();
            }}
          >
            {submitBtnLabel || "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog;
