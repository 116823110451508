import { IMG_REGEX } from "@constants/index";

export const setRedirectTo = (redirectTo: string) => {
  sessionStorage.setItem("redirectTo", redirectTo);
};

export const getRedirectTo = () => {
  return sessionStorage.getItem("redirectTo");
};

export const clearRedirectTo = () => {
  sessionStorage.removeItem("redirectTo");
};

export const isValidUrl = (url: string, includeProtocol = false) => {
  if (includeProtocol && !url?.startsWith("http")) {
    return false;
  }

  const urlRegex = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );

  return urlRegex.test(url);
};

export const isValidYoutubeEmbedUrl = (url: string) => {
  const embedUrl = new RegExp(
    "^(?:https?:\\/\\/)?(?:www\\.)?(?:youtu\\.be\\/|youtube\\.com\\/(?:embed\\/|live\\/|v\\/|watch\\?v=|watch\\?.+&v=))((\\w|-){11})(?:\\S+)?$"
  );
  return embedUrl.test(url || "");
};

export const checkImgURL = (url: string) => {
  // return(url.match(/\.(jpeg|jpg|gif|png|webp|ico)$/) != null);
  return url?.match(IMG_REGEX) != null;
};

export function getYouTubeThumbnailsVideo(url: string) {
  const videoIdRegex = new RegExp(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  );
  const match = url?.match(videoIdRegex);
  const videoId = match ? match[1] : null;
  const thumbnailsVideoUrl = `http://img.youtube.com/vi/${videoId}/0.jpg`;
  return videoId ? thumbnailsVideoUrl : null;
}

export const getYouTubeVideoId = (url: string) => {
  if (!url) {
    return "";
  }
  const newUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/live\/)/);
  return newUrl[2] !== undefined ? newUrl[2].split(/[^0-9a-z_\\-]/i)[0] : newUrl[0];
};

export const getProjectIdFromPublishProjectUrl = (url: string) => {
  if (!url) {
    return "";
  }
  const projectUrl = new URL(url?.split("?")?.[0]);
  const newUrl = projectUrl.pathname.split(/(\/published-projects\/)/);

  return newUrl[2] ?? "";
};

export const convertToUrl = (url = ""): string => {
  if (url.includes("://")) {
    return url;
  }
  return "//" + url;
};
