import { rtbInstance } from "@api/instance";
import { useMutation } from "@tanstack/react-query";

import { axios } from "@features/config";

type Request = {
  payload: {
    avatar?: string;
    roundTableTitle: string;
    projectTitle: string;
    isPublic: boolean;
    nonDisclosure: boolean;
    requireApplication: boolean;
    category?: string;
    tags?: string[];
    description?: string;
    files?: string[];
    invitees?: string[];
    location?: string;
    language?: string;
    managementStyle?: string;
    institution?: string;
  };
};

type Response = {
  dataroundTableId?: string;
  projectId?: string;
  projectShortId?: string;
};

export const createFirstProjectWithRoundtable = async ({
  payload,
}: Request): Promise<Response> => {
  const response = await rtbInstance.post(`/round-tables/project`, {
    ...payload,
  });

  return response.data;
};
