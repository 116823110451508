import CryptoJS from "crypto-js";
import { v4 } from "uuid";

export class IziCrypto {
  static generateKey() {
    return v4().replace(/-/g, "").split("").reverse().join("");
  }
  static decodeKey(_key: string) {
    return _key.split("").reverse().join("");
  }
  static encrypt(_data: string, _key: string) {
    return CryptoJS.AES.encrypt(_data, this.decodeKey(_key)).toString();
  }
  static decode(_data: string, _key: string) {
    return CryptoJS.AES.decrypt(_data, this.decodeKey(_key)).toString(CryptoJS.enc.Utf8);
  }
}
