import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from "react";
import { AiOutlineCloseCircle, AiOutlineFileImage } from "react-icons/ai";

import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  styled,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";

import EllipsisContent from "@components/EllipsisContent";

import { checkImgURL } from "@utils/helpers/urlHelper";

import { ReactComponent as TrashIcon } from "@assets/icons/trash.svg";
import { ReactComponent as UploadIcon } from "@assets/icons/upload.svg";

import { useSnackbar } from "@contexts/SnackbarContext";

export const toBase64 = async (filesList: (string | File)[]) => {
  if (!filesList?.length) {
    return;
  }

  const promises = [];

  const newImages: string[] = [];
  const newDocuments: string[] = [];

  for (let i = 0; i < filesList.length; i++) {
    const file = filesList[i];
    const filePromise = new Promise((resolve) => {
      if (!(file instanceof File)) {
        return resolve(file);
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
    });
    promises.push(filePromise);
  }

  const [...base64FileContents] = await Promise.all(promises);

  base64FileContents.forEach((content) => {
    if (typeof content === "string") {
      if (content.startsWith("data:image") || checkImgURL(content)) {
        newImages.push(content);
      } else if (content.startsWith("data:application")) {
        newDocuments.push(content);
      }
    }
  });

  return { images: newImages, documents: newDocuments };
};

export const ACCEPTED_FILE_TYPE = {
  "all": "image/*, .pdf, .doc, .docx",
  "image": "image/*",
  "document": ".pdf, .doc, .docx",
};

interface IProps {
  initialFiles?: string[];
  roundtableId?: string;
  userId?: string;
  acceptedFileTypes?: keyof typeof ACCEPTED_FILE_TYPE;
  setIsDirtyFiles?: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps;
  suffixText?: string;
  disabled?: boolean;
  onChangeFiles?: (data: File[]) => void;
}

const UploadAttachments = forwardRef<unknown, IProps>(function MultiFileUploader(
  { initialFiles, disabled, onChangeFiles },
  ref
) {
  const {
    palette: { common },
  } = useTheme();
  const imageSelectId = useId();
  const [files, setFiles] = useState([]);
  const [fileUris, setFileUris] = useState<string[]>([]);
  const [renderFiles, setRenderFiles] = useState<number | string>(128);

  useImperativeHandle(ref, () => ({
    files,
    fileUris,
  }));

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }

    const localFiles = e.currentTarget.files;
    const newFiles: File[] = [];

    for (let i = 0; i < localFiles.length; i++) {
      const file = localFiles[i];

      newFiles.push(file);
    }

    const newFileList = [...files, ...newFiles];
    if (onChangeFiles) {
      onChangeFiles(newFileList);
    }

    setFiles(newFileList);
  };

  const handleRemoveFile = ({ index }: { index: number }) => {
    const newFileList = files.filter((_, idx) => index !== idx) || [];
    if (onChangeFiles) {
      onChangeFiles(newFileList);
    }

    setFiles(newFileList);
  };

  useEffect(() => {
    if (initialFiles?.length > 0) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  useEffect(() => {
    toBase64(files).then((fileContents) => {
      setFileUris([...(fileContents?.documents || []), ...(fileContents?.images || [])]);
      if (renderFiles) {
        setTimeout(() => {
          setRenderFiles("max-content");
        }, 220);
      }
    });
  }, [files]);

  return (
    <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
      <Box
        component="label"
        htmlFor={imageSelectId}
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          mb: 0.5,
          borderRadius: 2,
          gap: 1,
          bgcolor: "common.white",
          fontSize: 14,
          width: 161,
          height: 40,
          cursor: disabled ? "default" : "pointer",
          border: common.border,
          ":hover": {
            backgroundColor: disabled ? "transparent" : "#eee",
          },
        }}
      >
        <UploadIcon />
        Upload attachment
        <VisuallyHiddenInput
          onChange={handleUploadImage}
          disabled={disabled}
          id={imageSelectId}
          onClick={(event) => {
            event.currentTarget.value = null;
          }}
          type="file"
          accept="image/*"
          multiple
        />
      </Box>
      <Box
        display={"flex"}
        gap={0.5}
        flexDirection={"column"}
        sx={{
          overflowX: "auto",
          maxWidth: "100%",
        }}
      >
        {files?.map((file, index) => (
          <Stack
            key={index}
            direction={"row"}
            alignItems={"center"}
            height={36}
            justifyContent={"flex-end"}
          >
            <EllipsisContent
              fontSize={16}
              lineHeight={1.5}
              fontWeight={400}
              numberOfLines={1}
              sx={{ wordBreak: "break-word", m: 0 }}
              content={file.name}
            />
            <IconButton
              onClick={() => handleRemoveFile({ index })}
              sx={{}}
              color="primary"
            >
              <TrashIcon />
            </IconButton>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
});

export default UploadAttachments;

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
