import { ReactNode, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { createJob } from "@api/job/useCreateJob";
import { createFirstProjectWithRoundtable } from "@api/rtb/useCreateFirstNewProjectWithRoundtable";
import useFindUserSkills from "@api/skills/useFindUserSkills";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AttachMoney as AttachMoneyIcon,
  CalendarToday as CalendarIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import TrackChangesSharpIcon from "@mui/icons-material/TrackChangesSharp";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
  createFilterOptions,
  FilterOptionsState,
  CssBaseline,
  styled,
  GridProps,
  Tooltip,
  Divider,
  StackProps,
  MenuItem,
  Avatar,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PublicPaths } from "@routers/path/PublicPath";
import { iziRoute } from "@routers/routes";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import * as yup from "yup";

import Button from "@components/Button";
import ConfirmDialog from "@components/ConfirmDialog/ConfirmAction";
import FormInput from "@components/FormInput";
import Loading from "@components/Loading";

import { Footer, Header } from "@pages/gatherlance-landing/components/HeaderAndFooter";

import { uploadImagesToServer } from "@utils/uploadImageToServer";

import bgBottom from "@assets/images/background_job.png";

import { ManagementEnum } from "@dto/roundtable";

import { useSnackbar } from "@contexts/SnackbarContext";
import { useUserStore } from "@contexts/user.state";

import { ENV } from "@config/env";

import ProjectDisplay from "./components/ProjectDisplay";
import SuccessCreateJobDialog from "./components/SuccessCreateJobDialog";
import UploadAttachments from "./components/UploadFile";
import { PostJobFormType, Priority, QuestVisibility } from "./types";
import { formatPriorityStyle } from "./utils";

const PriorityOptions = [Priority.URGENT, Priority.HIGH, Priority.MEDIUM, Priority.LOW];
export const VisibilityOptions = [QuestVisibility.PLATFORM, QuestVisibility.PROJECT];

const formSchema = yup.object().shape({
  title: yup.string().required("Title is required."),
  description: yup.string().required("Description is required."),
  deliverables: yup.string().required("Deliverables is required"),
  budget: yup
    .number()
    .typeError("Budget must be a number")
    .required("Budget is required.")
    .min(5, "Minimum budget is 5 US Dollars"),
  skills: yup
    .array()
    .of(yup.object())
    .min(1, "Skills required at least 1")
    .required("Skills is required"),
  regional: yup.string(),
  project: yup.object().shape({
    id: yup.string().when("name", {
      is: (name: string) => name == null || name == "",
      then: (schema) =>
        schema.required("You need to enter project name or select project"),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
});

const defaultValues: PostJobFormType = {
  title: "",
  description: "",
  files: [],
  deliverables: "",
  skills: [],
  budget: null,
  dueDate: new Date().toString(),
  project: {
    rtbId: "",
    id: "",
    name: "",
  },
  regional: "WORLD",
  priority: PriorityOptions[2],
  visibility: VisibilityOptions[0],
};

type SelectedField = {
  key: keyof PostJobFormType | "attachment" | "";
  type: "text" | "number" | "";
};
let timeOutSearch: any = 0;
const filter = createFilterOptions();

export default function PostJobForm() {
  const [editField, setEditField] = useState<SelectedField>({ key: "", type: "" });
  const [jobPath, setJobPath] = useState("");
  const [skillInput, setSkillInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState("");
  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(formSchema) as any,
  });
  const {
    palette: { common },
  } = useTheme();
  const isNarrowWidth = false;
  const user = useUserStore((state) => state.user);
  const { setSnackbar } = useSnackbar();
  // const { mutate: createJob, isLoading } = useCreateJob();
  const { mutate: createJobGtl, isPending } = useMutation({
    mutationFn: createJob,
  });
  const {
    trigger,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = formMethods;

  const watchedPriority = watch("priority");
  const watchedVisibility = watch("visibility");
  const watchedProject = watch("project");

  const {
    skills: userSkills,
    isLoading: loadingSkills,
    handleChangeParams,
  } = useFindUserSkills({});

  const onConfirmPost = () => {
    setShowConfirmation("post");
  };

  const createNewProject = async (projectName: string, rtbId: string) => {
    const payload: any = {
      managementStyle: ManagementEnum.TRADITIONAL,
      roundTableId: rtbId,
      projectTitle: projectName,
      invitees: [],
      files: [],
      publishData: {
        information: ["MEMBERS"],
      },
    };
    const newProject = await createFirstProjectWithRoundtable({ payload });

    return newProject;
  };

  useEffect(() => {
    handleChangeParams({ searchContent: skillInput });
  }, [skillInput, handleChangeParams]);

  const renderGridField = (
    text?: string,
    startIcon?: ReactNode,
    gridProps?: GridProps
  ) => {
    return (
      <Grid
        item
        xs={3}
        {...gridProps}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          minHeight: "40px",
        }}
      >
        <Stack
          pt={1}
          flexDirection={"row"}
          alignItems={"center"}
          color={"common.gray5"}
          gap={1}
        >
          {startIcon}
          <Typography color={"common.gray5"} fontSize={14}>
            {text}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const renderGridValueContainer = useCallback(
    ({
      children,
      gridCol,
      slotProps,
    }: {
      children: ReactNode;
      gridCol: number;
      slotProps?: StackProps;
    }) => {
      return (
        <Grid item xs={gridCol} alignContent={"flex-start"} sx={{ h: 40 }}>
          <Stack
            borderRadius={1.5}
            overflow={"hidden"}
            {...slotProps}
            // sx={{ px: 1 }}
            onClick={slotProps?.onClick}
          >
            {children}
          </Stack>
        </Grid>
      );
    },
    [errors, control, userSkills, editField]
  );
  const renderEmptyFieldValue = (defaultText?: string) => {
    return (
      <Typography
        fontStyle={"italic"}
        component={"span"}
        fontSize={14}
        color={"common.gray4"}
      >
        {defaultText}
      </Typography>
    );
  };

  const onClickDraft = () => {
    if (!getValues("title") || (!watchedProject.id && !watchedProject.name)) {
      trigger(["title", "project"]);
    } else {
      setShowConfirmation("draft");
    }
  };

  const onSaveDraft = async () => {
    const formData = watch();
    setLoading(true);
    const { files, project } = formData;
    let createdProject;
    if (project?.name && !project.id) {
      createdProject = await createNewProject(project.name, project.rtbId);
    }

    const fileList: string[] = [];
    if (files?.length > 0) {
      const fileResults = await uploadImagesToServer(files);
      fileList.push(...fileResults);
    }

    const payload = {
      ...formData,
      projectId: project.id || createdProject.projectId,
      attachment: fileList,
      isQuest: true,
    };
    createJobGtl(payload, {
      onSuccess: (apiData) => {
        const path = `${ENV.RTB_APP_URL}/chat/${apiData.projectId}?job=${apiData.shortId}&r=&l=1&m=3`;
        setJobPath(path);
        setLoading(false);
      },
      onError: (error) => {
        setSnackbar({
          message: error,
          open: true,
          severity: "error",
        });
        setLoading(false);
      },
    });
  };

  const onCreateNewProject = async (projectName: string, rtbId: string) => {
    const payload: any = {
      managementStyle: ManagementEnum.TRADITIONAL,
      roundTableId: rtbId,
      projectTitle: projectName,
      invitees: [],
      files: [],
      publishData: {
        information: ["MEMBERS"],
      },
    };
    const newProject = await createFirstProjectWithRoundtable({ payload });

    return newProject;
  };
  const onPostJob = handleSubmit(async (data: PostJobFormType) => {
    const { files, project } = data;

    setLoading(true);

    let newProject;
    if (project.name && !project.id) {
      newProject = await onCreateNewProject(project.name, project.rtbId);
    }

    const fileList: string[] = [];
    if (files?.length > 0) {
      const fileResults = await uploadImagesToServer(files);
      fileList.push(...fileResults);
    }

    const payload = {
      ...data,
      projectId: project.id || newProject.projectId,
      isPublic: true,
      attachment: fileList,
    };

    createJobGtl(payload, {
      onSuccess: (apiData) => {
        const path = `/landing/job-details/${apiData.jobId}`;

        setJobPath(path);
        setLoading(false);
      },
      onError: (error) => {
        setSnackbar({
          message: error,
          open: true,
          severity: "error",
        });
        setLoading(false);
      },
    });
  });

  const handleEditField = (key: SelectedField["key"], type: SelectedField["type"]) => {
    setEditField({ key, type });
  };

  return (
    <Stack>
      <CssBaseline />
      <Stack
        sx={{ position: "sticky", top: 0, width: "100%", bgcolor: "white", zIndex: 10 }}
      >
        <Header />
      </Stack>

      <Stack
        px={{ sm: 3, md: 10 }}
        sx={{
          backgroundImage: `url(${bgBottom})`,
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container item pt={4} minHeight={600} justifyContent={"center"}>
          <Grid item xs={12} md={8} justifyContent={"center"}>
            <Typography fontWeight={700} fontSize={{ xs: 24, md: 30 }} pt={10} pb={6}>
              Create a quest/ticket
            </Typography>
          </Grid>

          <Grid xs={12} md={8}>
            <Stack component={"div"} border={common.border} borderRadius={4}>
              <Stack
                p={1.6}
                flexDirection={"row"}
                justifyContent={"space-between"}
                gap={1.5}
                flexWrap={"wrap"}
              >
                <FormInput
                  id="title"
                  control={control}
                  name="title"
                  placeholder="Title"
                  onBlur={() => handleEditField("", "")}
                  size="small"
                  required
                  error={!!errors?.title?.message}
                  helperText={errors?.title?.message}
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    ".MuiInput-input": {
                      fontSize: 28,
                      fontWeight: 700,
                      lineHeight: "23px",
                      padding: 0,
                    },
                  }}
                />
              </Stack>
              <Divider sx={{ borderColor: "common.bellflowerBlue" }} />
              <Stack
                p={isNarrowWidth ? 1.5 : 2.5}
                gap={3}
                height={"calc(100% - 68px)"}
                overflow={"auto"}
              >
                <Grid container columnSpacing={1.5}>
                  {renderGridField(
                    "Priority",
                    <TrackChangesSharpIcon sx={{ width: 18, height: 18 }} />,
                    { xs: isNarrowWidth ? 5 : 3 }
                  )}
                  {renderGridValueContainer({
                    gridCol: isNarrowWidth ? 7 : 3,
                    slotProps: { onClick: () => handleEditField("priority", "text") },
                    children: (
                      <FormInput
                        control={control}
                        select
                        key={`priority-${watchedPriority}`}
                        id="priority"
                        name="priority"
                        placeholder="Priority"
                        onBlur={() => handleEditField("", "")}
                        required
                        error={!!errors?.priority?.message}
                        helperText={errors?.priority?.message}
                        sx={{
                          bgcolor:
                            editField.key === "priority"
                              ? "common.deutziaWhite"
                              : "transparent",
                          ".MuiSelect-select": {
                            py: 1,
                            ":focus": { bgcolor: "common.deutziaWhite" },
                          },
                        }}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        SelectProps={{
                          IconComponent: () => <></>,
                          renderValue: (val: unknown) => (
                            <Typography
                              sx={{
                                ...formatPriorityStyle(getValues("priority")),
                                width: "fit-content",
                              }}
                            >
                              {val as string}
                            </Typography>
                          ),
                        }}
                      >
                        {PriorityOptions.map((val) => (
                          <MenuItem
                            key={val}
                            value={val}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {val}
                          </MenuItem>
                        ))}
                      </FormInput>
                    ),
                  })}

                  {renderGridField(
                    "Budget",
                    <AttachMoneyIcon sx={{ width: 18, height: 18 }} />,
                    {
                      xs: isNarrowWidth ? 5 : 3,
                    }
                  )}
                  {renderGridValueContainer({
                    gridCol: isNarrowWidth ? 7 : 3,
                    slotProps: { onClick: () => handleEditField("budget", "number") },
                    children: (
                      <FormInput
                        control={control}
                        id="budget"
                        name="budget"
                        type="number"
                        placeholder="Budget"
                        onBlur={() => handleEditField("", "")}
                        required
                        error={!!errors?.budget?.message}
                        helperText={errors?.budget?.message}
                        sx={{
                          bgcolor:
                            editField.key === "budget"
                              ? "common.deutziaWhite"
                              : "transparent",
                          ".MuiInput-input": { py: 1, fontSize: 14 },
                        }}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ),
                  })}

                  {renderGridField(
                    "Visibility",
                    <RemoveRedEyeOutlinedIcon sx={{ width: 18, height: 18 }} />,
                    { xs: isNarrowWidth ? 5 : 3 }
                  )}
                  {renderGridValueContainer({
                    gridCol: isNarrowWidth ? 7 : 3,
                    slotProps: { onClick: () => handleEditField("visibility", "text") },
                    children: (
                      <FormInput
                        control={control}
                        select
                        key={`visibility-${watchedVisibility}`}
                        id="visibility"
                        name="visibility"
                        placeholder="Visibility"
                        onBlur={() => handleEditField("", "")}
                        required
                        error={!!errors?.visibility?.message}
                        helperText={errors?.visibility?.message}
                        sx={{
                          bgcolor:
                            editField.key === "visibility"
                              ? "common.deutziaWhite"
                              : "transparent",
                          ".MuiSelect-select": {
                            py: 1,
                            ":focus": { bgcolor: "common.deutziaWhite" },
                          },
                        }}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        SelectProps={{
                          IconComponent: () => <></>,
                          renderValue: (val: unknown) => (
                            <Typography fontSize={14} textTransform={"capitalize"}>
                              {val as string}
                            </Typography>
                          ),
                        }}
                      >
                        {VisibilityOptions.map((val) => (
                          <MenuItem
                            key={val}
                            value={val}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {val}
                          </MenuItem>
                        ))}
                      </FormInput>
                    ),
                  })}

                  {renderGridField(
                    "Created by",
                    <PersonPinOutlinedIcon sx={{ width: 18, height: 18 }} />,
                    { xs: isNarrowWidth ? 5 : 3 }
                  )}
                  <Grid item xs={isNarrowWidth ? 7 : 3} alignContent={"center"}>
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} py={0.5}>
                      <Avatar
                        src={user?.avatar}
                        alt="createdUser"
                        sx={{ width: 24, height: 24, ml: 1 }}
                      />
                      <Typography fontSize={14} noWrap>
                        {user?.name}
                      </Typography>
                    </Stack>
                  </Grid>

                  {renderGridField(
                    "Due date",
                    <CalendarIcon sx={{ width: 18, height: 18 }} />,
                    {
                      xs: isNarrowWidth ? 5 : 3,
                    }
                  )}
                  {renderGridValueContainer({
                    gridCol: isNarrowWidth ? 7 : 9,
                    slotProps: { onClick: () => handleEditField("dueDate", "") },
                    children: (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          value={dayjs(getValues("dueDate"))}
                          onChange={(selectedDateTime) => {
                            setValue("dueDate", dayjs(selectedDateTime).toISOString(), {
                              shouldDirty: true,
                            });
                          }}
                          slots={{
                            openPickerIcon: () => null,
                          }}
                          onClose={() => handleEditField("", "")}
                          slotProps={{
                            textField: {
                              required: true,
                              error: !!errors?.dueDate?.message,
                              helperText: errors?.dueDate?.message,
                              sx: {
                                ".MuiInput-input": { py: 1, fontSize: 14 },
                                width: "100%",
                              },
                              variant: "standard",
                              InputProps: { disableUnderline: true },
                            },
                            openPickerButton: {
                              sx: {
                                height: "100%",
                                width: "100%",
                                borderRadius: "unset",
                                p: 10,
                                ":hover": {},
                              },
                            },
                            inputAdornment: {
                              sx: {
                                position: "absolute",
                                right: 0,
                                height: "100%",
                                width: "100%",
                                borderRadius: "unset",
                                m: 0,
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    ),
                  })}

                  {renderGridField(
                    "Skills",
                    <VerifiedOutlinedIcon sx={{ width: 18, height: 18 }} />,
                    {
                      xs: isNarrowWidth ? 5 : 3,
                      sx: { alignContent: "initial", "&.MuiGrid-item": { pt: 1 } },
                    }
                  )}
                  {renderGridValueContainer({
                    gridCol: isNarrowWidth ? 7 : 9,
                    children: (
                      <>
                        {editField.key === "skills" ? (
                          <Autocomplete
                            id="skills"
                            multiple
                            freeSolo
                            isOptionEqualToValue={(option: any, value: any) =>
                              option?.text === value?.text
                            }
                            autoHighlight
                            filterSelectedOptions
                            loading={loadingSkills}
                            onInputChange={(event: any, newInputValue: string) => {
                              if (timeOutSearch) {
                                clearTimeout(timeOutSearch);
                              }
                              timeOutSearch = setTimeout(async () => {
                                setSkillInput(newInputValue);
                              }, 1000);
                            }}
                            getOptionLabel={(option: any) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.text) {
                                return option.text;
                              }
                              return option.value;
                            }}
                            renderOption={(props, option: any) => {
                              return (
                                <li {...props}>
                                  <Grid container alignItems="center">
                                    <Grid
                                      item
                                      sx={{
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <Typography variant="body2" color="text.secondary">
                                        {option.label || option.text}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </li>
                              );
                            }}
                            {...(loadingSkills && {
                              renderOption: () => (
                                <Stack px={2}>
                                  <Loading />
                                </Stack>
                              ),
                            })}
                            filterOptions={(
                              options: any[],
                              params: FilterOptionsState<unknown>
                            ) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some(
                                (option) => inputValue === option.text
                              );
                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  label: `${inputValue}`,
                                  text: inputValue,
                                });
                              }
                              return filtered;
                            }}
                            value={getValues("skills")}
                            onChange={(event: any, newValue: any[]) => {
                              setValue("skills", newValue);
                              trigger("skills");
                            }}
                            onBlur={() => {
                              handleEditField("", "");
                            }}
                            options={userSkills?.map(({ id, name }) => ({
                              value: id,
                              text: name,
                            }))}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Press enter to add new skill"
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((tag: any, index) => (
                                <Chip
                                  key={`${index}-chip-label`}
                                  variant="outlined"
                                  label={tag?.label || tag?.text || ""}
                                  {...getTagProps({ index })}
                                  color="primary"
                                  size="small"
                                />
                              ))
                            }
                            sx={{
                              "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                                minWidth: "unset",
                              },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                            }}
                          />
                        ) : (
                          <Stack
                            flexDirection={"row"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                            gap={1}
                            p={1}
                            onClick={() => handleEditField("skills", "text")}
                          >
                            {!(getValues("skills")?.length > 0) &&
                              renderEmptyFieldValue("Skills")}
                            {getValues("skills")?.map((s: any, i) => (
                              <Chip
                                key={i}
                                label={s.text}
                                sx={{
                                  fontSize: 14,
                                  color: "common.gray7",
                                  bgcolor: "common.gray1",
                                }}
                              />
                            ))}
                          </Stack>
                        )}
                        {errors?.["skills"]?.message && (
                          <Typography
                            component={"p"}
                            fontSize={12}
                            sx={{
                              mt: 0.5,
                              color: "#d32f2f",
                            }}
                          >
                            {errors?.["skills"]?.message}
                          </Typography>
                        )}
                      </>
                    ),
                  })}
                </Grid>

                <Stack gap={1.5}>
                  <Typography fontSize={18} fontWeight={700}>
                    Quest overview
                  </Typography>
                  <FormInput
                    id="description"
                    control={control}
                    name="description"
                    placeholder="Description"
                    onBlur={() => handleEditField("", "")}
                    onFocus={() => handleEditField("description", "text")}
                    multiline
                    minRows={5}
                    size="small"
                    inputProps={{ style: { fontSize: 14 } }}
                    error={!!errors?.description?.message}
                    helperText={errors?.description?.message}
                  />
                </Stack>
                <Stack gap={1.5}>
                  <Typography fontSize={18} fontWeight={700}>
                    Deliverables
                  </Typography>
                  <FormInput
                    id="deliverables"
                    control={control}
                    name="deliverables"
                    placeholder="Deliverables"
                    onBlur={() => handleEditField("", "")}
                    onFocus={() => handleEditField("deliverables", "text")}
                    multiline
                    minRows={5}
                    size="small"
                    error={!!errors?.deliverables?.message}
                    helperText={errors?.deliverables?.message}
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                </Stack>
                <Stack gap={1.5}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={0.7}>
                    <Typography fontSize={18} fontWeight={700}>
                      Ticket Content
                    </Typography>
                    <Tooltip title="You can provide additional instructions so once the ticket is assigned, freelancers can start immediately">
                      <InfoIcon sx={{ height: 14, width: 14, cursor: "pointer" }} />
                    </Tooltip>
                  </Stack>
                  <FormInput
                    id="detailOfWork"
                    control={control}
                    name="detailOfWork"
                    placeholder="Detail of Work"
                    onBlur={() => handleEditField("", "")}
                    onFocus={() => handleEditField("detailOfWork", "text")}
                    multiline
                    minRows={5}
                    size="small"
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                </Stack>
                <Stack gap={1.5}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={0.7}>
                    <Typography fontSize={18} fontWeight={700}>
                      Ticket Attachment
                    </Typography>
                    <Tooltip title="You can upload the required files in advance, so once the ticket is assigned, freelancers can start immediately">
                      <InfoIcon sx={{ height: 14, width: 14, cursor: "pointer" }} />
                    </Tooltip>
                  </Stack>
                  <UploadAttachments onChangeFiles={(data) => setValue("files", data)} />
                </Stack>
                {renderGridValueContainer({
                  gridCol: isNarrowWidth ? 7 : 12,
                  slotProps: { onClick: () => handleEditField("visibility", "text") },
                  children: (
                    <FormProvider {...formMethods}>
                      <ProjectDisplay />
                    </FormProvider>
                  ),
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={12} md={8}>
            <Stack
              gap={1.5}
              flex={1}
              height={92}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ActionButton disabled={isPending || loading} onClick={onClickDraft}>
                Save draft
              </ActionButton>
              <ActionButton
                disabled={isPending || loading}
                onClick={handleSubmit(onConfirmPost)}
                primary
              >
                Post this job
              </ActionButton>
            </Stack>
          </Grid>
          {!!showConfirmation && (
            <ConfirmDialog
              title={showConfirmation === "draft" ? "Save draft" : "Post job"}
              description={
                showConfirmation === "draft"
                  ? "Your job will be saved as draft. Are you sure to save it?"
                  : "Your job will be posted on the Gatherlance. Are you sure to post it?"
              }
              submitBtnLabel={showConfirmation === "draft" ? "Save it" : "Post it"}
              cancelBtnLabel="Let me check again!"
              isOpen={!!showConfirmation}
              onSubmit={() =>
                showConfirmation === "draft" ? onSaveDraft() : onPostJob()
              }
              onClose={() => setShowConfirmation("")}
            />
          )}
          {jobPath && (
            <SuccessCreateJobDialog
              isOpen={Boolean(jobPath)}
              showDraft={jobPath.includes("/chat")}
              onClose={() => iziRoute.navigate(PublicPaths.landing)}
              jobPath={jobPath}
            />
          )}
        </Grid>
      </Stack>
      <Box mt={"95px"}>
        <Footer />
      </Box>
    </Stack>
  );
}
const ActionButton = styled(Button)<{ primary?: boolean }>(({ primary }) => ({
  color: primary ? "#fff" : "#000",
  backgroundColor: primary ? "#000" : "#fff",
  width: 140,
  padding: "12px auto",
  fontSize: 16,
  fontWeight: 600,
  borderRadius: 100,
  "&:hover": {
    backgroundColor: primary ? "#0000005e" : "#0000005e",
    color: primary ? "#fff" : "#000",
  },
}));
